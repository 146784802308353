<template>
  <eden-confirm-dialog
    title="Send Notification Issue"
    :button-type="'success'"
    :button-text="'Send'"
    :button-status="sending"
    :show.sync="setShow"
    @confirm="sendNotification"
  >
    <p>Proceed to send notification to all resolvers?</p>
  </eden-confirm-dialog>
</template>

<script>
import issues from "@/requests/feedback/issues";

export default {
  name: "IssueNotification",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    issue: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      sending: false,
    };
  },
  computed: {
    issueId() {
      return this.$route.params.id;
    },
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeEvent() {
      this.setShow = false;
    },
    sendNotification() {
      this.sending = true;
      const payload = {
        issue_id: this.issueId
      }
      issues
        .notify(payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success");
            this.sending = false;
            this.closeEvent();
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.sending = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
